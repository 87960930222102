import React from 'react';
import { Link } from 'react-router-dom';
import AppHomepageCover from '../../assets/images/urgence-plombier-serrurier-montpellier-pas-cher-cover.jpeg';
import IconPlombier from '../../assets/images/plombier-service.svg';
import IconSerrurier from '../../assets/images/serrurier-service.svg';
import { BsFillPhoneVibrateFill } from 'react-icons/bs';
import { MdMarkEmailRead } from 'react-icons/md';
import { Button } from 'react-bootstrap';
import PlombierImageWithTools from '../../assets/images/plombier-section-homepage-image.svg';
import PricingGeoImage from '../../assets/images/carte-agglomeration-montpellier.jpeg';
import PlombierPricingImage from '../../assets/images/urgence_plomberie_pricing_image.jpeg';
import SerrurierPricingImage from '../../assets/images/urgence_serrurerie_pricing_image.jpeg';
import PlombierImageWithTools2 from '../../assets/images/urgence_plomberie_two.jpeg';
import SerrurierImageWithTools2 from '../../assets/images/serrurier-section-homepage-image-2.jpeg';
import SerrurierImageAbsolute from '../../assets/images/serrurier-homepage-absolute.jpeg';
import ReparationFuitesImage from '../../assets/images/services-plombier/reparation-de-fuites.webp';
import DebouchageDeCanalisations from '../../assets/images/services-plombier/debouchage-de-canalisations.webp';
import InstallationSanitaire from '../../assets/images/services-plombier/installation-sanitaire.jpeg';
import OuvertureDePortesFermees from '../../assets/images/services-serrurier/ouverture-de-portes-fermees.png';
import ChangementDeSerrure from '../../assets/images/services-serrurier/changement-de-serrure.jpeg';
import InstallationPortesBlindees from '../../assets/images/services-serrurier/installation-portes-blindees.jpeg';
import PlomberieGenerale from '../../assets/images/services-plombier/plomberie-generale.jpeg';
import DepannageDUrgenceSerrurier from '../../assets/images/services-serrurier/depannage-durgence-serrurier.jpeg';

const Homepage = () => {
    return (
        <div className="app-homepage-component">
            <section id="app-homepage-cover">
                <div 
                    className="app-homepage-cover-container"
                    style={{
                        background: `linear-gradient(rgba(39, 11, 216, 0.45),rgba(39, 11, 216, 0.45)), url(${AppHomepageCover}) no-repeat center center fixed`,
                        backgroundSize: 'cover'
                    }}
                >
                    <div className="row app-homepage-cover-row">
                        <div className="col-lg-8 app-homepage-cover-col">
                            <div className="app-homepage-cover-textual-container">
                                <h1 className="app-cover-textual-section-title">
                                    Urgence Plombier et Serrurier pas cher à Montpellier
                                </h1>
                                <div className="app-cover-textual-section-content">
                                    <p>
                                        Besoin d'un plombier ou d'un serrurier d'urgence à Montpellier ?
                                    </p>
                                    <p>
                                        <strong>Urgence Plombier et Serrurier Montpellier</strong> est votre solution. Nous mettons à votre disposition des professionnels compétents et abordables, prêts à intervenir 24/7 pour résoudre vos problèmes de plomberie et de serrurerie.
                                    </p>
                                    <p>
                                        Avec des années d'expérience, nous offrons un service d'urgence rapide et fiable, répondant à tous vos besoins de réparation, d'installation et de dépannage. Nos tarifs compétitifs et notre engagement envers la satisfaction client font de nous un choix de confiance. Contactez-nous dès maintenant pour une assistance d'urgence de qualité à un prix abordable.
                                    </p>
                                </div>
                            </div>
                            <div className="app-cta-btns-container">
                                <a href="tel:0646217592" className="app-cta-btn cta-phone-btn">
                                    <BsFillPhoneVibrateFill />
                                    <span>06 46 21 75 92</span>
                                </a>
                                <a href="mailto:contact@intervention-plombier-serrurier24h.fr" className="app-cta-btn cta-email-btn">
                                    <MdMarkEmailRead />
                                    <span>Demander un devis</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="app-services-container container">
                    <div className="app-service-item-row row">
                        <div className="app-service-card-container col-lg-6">
                            <div className="app-service-card">
                                <img src={ IconSerrurier} alt="Urgence Plombier Montpellier pas cher" className="app-service-card-image" />
                                <h2 className="app-service-card-title">Urgence Plombier Montpellier</h2>
                                <div className="app-service-card-textual-body">
                                    <p>En cas d'urgence plomberie à Montpellier, il est essentiel de pouvoir compter sur les services d'un plombier professionnel et fiable.</p>
                                    <p>Urgence Plombier Montpellier met à votre disposition une équipe d'experts est prête à intervenir 24 heures sur 24, 7 jours sur 7, pour résoudre tous vos problèmes de plomberie et de serrurerie : 
                                        <ul className="service-in-list">
                                            <li><strong>Plomberie Générale</strong></li>
                                            <li><strong>Réparation de fuites</strong></li>
                                            <li><strong>Débouchage de canalisations</strong></li>
                                            <li><strong>Installation de sanitaires</strong></li>
                                            <li><strong>Dégorgement WC</strong></li>
                                        </ul>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="app-service-card-container col-lg-6">
                            <div className="app-service-card">
                                <img src={IconPlombier} alt="Urgence Serrurier Montpellier pas cher" className="app-service-card-image" />
                                <h2 className="app-service-card-title">Urgence Serrurier Montpellier</h2>
                                <div className="app-service-card-textual-body">
                                    <p>Les problèmes de serrurerie peuvent survenir à tout moment, que ce soit une clé perdue, une serrure endommagée ou une porte claquée.</p>
                                    <p>Une situation pareille nécessite de faire appel à un serrurier qualifié qui puisse intervenir rapidement pour résoudre le problème de : 
                                        <ul className="service-in-list">
                                            <li><strong>Dépannage d'urgence</strong></li>
                                            <li><strong>Ouverture de portes fermées</strong></li>
                                            <li><strong>Changement de serrures</strong></li>
                                            <li><strong>Installation de portes blindées</strong></li>
                                            <li><strong>Remplacement de cylindre</strong></li>
                                        </ul>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </section>
            <section id="all-services">
                <div className="container all-services-container">
                    <div className="row all-services-row">
                        <div className="col-lg-12 all-services-col plumber-services-col">
                            <div className="all-services-content-body">
                                <div className="all-services-section-title-container">
                                    <div className="all-services-section-title-label-body">
                                        <div className="what-we-offer">Ce que nous offrons en plomberie</div>
                                        <h2 className="all-services-section-title">Services d'Urgence Plombier pas cher à Montpellier : Solutions Rapides et Abordables</h2>
                                    </div>
                                    <div className="all-services-section-title-description-body">
                                        <p>
                                            Besoin d'un plombier en urgence à Montpellier, mais à un prix abordable ? Notre équipe de plombiers expérimentés est disponible 24/7 pour répondre à vos urgences de plomberie. Nous intervenons rapidement pour minimiser les dégâts et vous offrir un confort optimal. Contactez-nous dès maintenant pour un service d'urgence plombier à Montpellier, avec des tarifs compétitifs.
                                        </p>
                                    </div>
                                </div>
                                <div className="all-services-items-containers">
                                    <div className="row service-items-row">
                                        <div className="col-lg-4 service-item-col">
                                            <div className="service-item-content-body-container">
                                                <div className="service-item-image-container">
                                                    <img src={PlomberieGenerale} alt="Plomberie générale avec Urgence Serrurier Montpellier pas cher, 24H/24 7J/7" className="service-item-image" />
                                                </div>
                                                <div className="service-item-title-and-description-container">
                                                    <div className="service-item-content">
                                                        <h3 className="service-item-name">Plomberie générale</h3>
                                                        <div className="service-item-description">
                                                            <p>
                                                                La plomberie générale englobe l'ensemble des installations, des réparations et de l'entretien liés aux systèmes de plomberie d'une propriété, un batiment ou une maison ou un appartement.
                                                            </p>
                                                        </div>
                                                        <Link to="/plombier-montpellier-pas-cher#app-page-service-plomberie-generale">en savoir plus</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 service-item-col">
                                            <div className="service-item-content-body-container">
                                                <div className="service-item-image-container">
                                                    <img src={ReparationFuitesImage} alt="Réparation de fuites d'eau avec Urgence Plombier Montpellier pas cher, 24H/24 7J/7" className="service-item-image" />
                                                </div>
                                                <div className="service-item-title-and-description-container">
                                                    <div className="service-item-content">
                                                        <h3 className="service-item-name">Réparation de fuites</h3>
                                                        <div className="service-item-description">
                                                            <p>
                                                                Une réparation de fuites consiste à remédier à toute fuite d'eau indésirable dans votre domicile ou vos installations.
                                                            </p>
                                                        </div>
                                                        <Link to="/plombier-montpellier-pas-cher#app-page-service-reparation-defuites">en savoir plus</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 service-item-col">
                                            <div className="service-item-content-body-container">
                                                <div className="service-item-image-container">
                                                    <img src={DebouchageDeCanalisations} alt="Débouchage de canalisation avec Urgence Plombier Montpellier pas cher, 24H/24 7J/7" className="service-item-image" />
                                                </div>
                                                <div className="service-item-title-and-description-container">
                                                    <div className="service-item-content">
                                                        <h3 className="service-item-name">Débouchage de canalisation</h3>
                                                        <div className="service-item-description">
                                                            <p>
                                                                Le débouchage de canalisations est le processus consistant à éliminer les obstructions et les bouchons qui peuvent se former dans vos canalisations. 
                                                            </p>
                                                        </div>
                                                        <Link to="/plombier-montpellier-pas-cher#app-page-service-debouchage-de-canalisation">en savoir plus</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 service-item-col">
                                            <div className="service-item-content-body-container">
                                                <div className="service-item-image-container">
                                                    <img src={InstallationSanitaire} alt="Installation de sanitaires avec Urgence Plombier Montpellier pas cher, 24H/24 7J/7" className="service-item-image" />
                                                </div>
                                                <div className="service-item-title-and-description-container">
                                                    <div className="service-item-content">
                                                        <h3 className="service-item-name">Installation de sanitaires</h3>
                                                        <div className="service-item-description">
                                                            <p>
                                                                L'installation de sanitaires fait référence au processus de mise en place des éléments essentiels de plomberie dans une salle de bains, des toilettes ou toute autre zone nécessitant des équipements sanitaires.
                                                            </p>
                                                        </div>
                                                        <Link to="/plombier-montpellier-pas-cher#app-page-service-installation-de-sanitaires">en savoir plus</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-8 service-item-promo-col">
                                            <div className="service-item-promo-container">
                                                <div className="service-item-promo-textual">
                                                    <p>Urgence Plombier Montpellier pas cher est disponible 24H/24, 7J/7</p>
                                                </div>
                                                <div className="service-item-promo-seo-description">
                                                    <p>
                                                        Nous intervenons rapidement pour résoudre vos problèmes de plomberie, que ce soit des fuites, des canalisations bouchées ou d'autres urgences. Avec notre équipe de plombiers expérimentés, vous bénéficiez d'un service de qualité à des tarifs compétitifs. Ne laissez pas les problèmes de plomberie gâcher votre journée. Contactez-nous dès maintenant pour une intervention rapide et pas cher.
                                                    </p>
                                                </div>
                                                <div className="app-cta-btns-container">
                                                    <a href="tel:0646217592" className="app-cta-btn cta-phone-btn">
                                                        <BsFillPhoneVibrateFill />
                                                        <span>06 46 21 75 92</span>
                                                    </a>
                                                    <a href="mailto:contact@intervention-plombier-serrurier24h.fr" className="app-cta-btn cta-email-btn">
                                                        <MdMarkEmailRead />
                                                        <span>Demander un devis</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="all-services-items-type-separator"></div>
                            <div className="all-services-content-body">
                                <div className="all-services-section-title-container">
                                    <div className="all-services-section-title-label-body">
                                        <div className="what-we-offer">Ce que nous offrons en serrurerie</div>
                                        <h2 className="all-services-section-title">Services d'Urgence Serrurier pas cher à Montpellier : Solutions Rapides et Abordables</h2>
                                    </div>
                                    <div className="all-services-section-title-description-body">
                                        <p>
                                            Besoin d'un serrurier en urgence à Montpellier, mais à un prix abordable ? Notre équipe de serruriers expérimentés est disponible 24/7 pour répondre à vos urgences serrurier. Nous intervenons rapidement pour minimiser les dégâts et vous offrir un confort optimal. Contactez-nous dès maintenant pour un service d'urgence plombier à Montpellier, avec des tarifs compétitifs.
                                        </p>
                                    </div>
                                </div>
                                <div className="all-services-items-containers">
                                    <div className="row service-items-row">
                                        <div className="col-lg-4 service-item-col">
                                            <div className="service-item-content-body-container">
                                                <div className="service-item-image-container">
                                                    <img src={DepannageDUrgenceSerrurier} alt="Dépannage d'urgence serrurier avec Urgence Serrurier Montpellier pas cher, 24H/24 7J/7" className="service-item-image" />
                                                </div>
                                                <div className="service-item-title-and-description-container">
                                                    <div className="service-item-content">
                                                        <h3 className="service-item-name">Dépannage d'urgence serrurier</h3>
                                                        <div className="service-item-description">
                                                            <p>
                                                                Le dépannage d'urgence serrurier est un service indispensable pour résoudre rapidement les problèmes liés aux serrures et aux systèmes de sécurité.
                                                            </p>
                                                        </div>
                                                        <Link to="/serrurier-montpellier-pas-cher#app-page-service-depannage-durgence-serrurier">en savoir plus</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 service-item-col">
                                            <div className="service-item-content-body-container">
                                                <div className="service-item-image-container">
                                                    <img src={OuvertureDePortesFermees} alt="Ouverture de portes fermées avec Urgence Serrurier Montpellier pas cher, 24H/24 7J/7" className="service-item-image" />
                                                </div>
                                                <div className="service-item-title-and-description-container">
                                                    <div className="service-item-content">
                                                        <h3 className="service-item-name">Ouverture de portes fermées</h3>
                                                        <div className="service-item-description">
                                                            <p>
                                                                L'ouverture de portes fermées est un service essentiel fourni par des professionnels qualifiés pour résoudre les problèmes d'accès à un espace verrouillé. 
                                                            </p>
                                                        </div>
                                                        <Link to="/serrurier-montpellier-pas-cher#app-page-service-ouverture-portes-fermees">en savoir plus</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 service-item-col">
                                            <div className="service-item-content-body-container">
                                                <div className="service-item-image-container">
                                                    <img src={ChangementDeSerrure} alt="Changement de serrure avec Urgence Plombier Montpellier pas cher, 24H/24 7J/7" className="service-item-image" />
                                                </div>
                                                <div className="service-item-title-and-description-container">
                                                    <div className="service-item-content">
                                                        <h3 className="service-item-name">Changement de serrure</h3>
                                                        <div className="service-item-description">
                                                            <p>
                                                                Le changement de serrure est un service important fourni par des professionnels de la serrurerie pour renforcer la sécurité de votre domicile, bureau ou local commercial.
                                                            </p>
                                                        </div>
                                                        <Link to="/plombier-montpellier-pas-cher#app-page-service-changemebt-de-serrure">en savoir plus</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 service-item-col">
                                            <div className="service-item-content-body-container">
                                                <div className="service-item-image-container">
                                                    <img src={InstallationPortesBlindees} alt="Installation de portes blindées avec Urgence Plombier Montpellier pas cher, 24H/24 7J/7" className="service-item-image" />
                                                </div>
                                                <div className="service-item-title-and-description-container">
                                                    <div className="service-item-content">
                                                        <h3 className="service-item-name">Installation de portes blindées</h3>
                                                        <div className="service-item-description">
                                                            <p>
                                                                L'installation d'une porte blindée est une mesure de sécurité essentielle pour protéger votre domicile, votre entreprise ou tout autre espace contre les intrusions et les effractions. 
                                                            </p>
                                                        </div>
                                                        <Link to="/plombier-montpellier-pas-cher#app-page-service-installation-de-portes-blindees">en savoir plus</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-8 service-item-promo-col">
                                            <div className="service-item-promo-container">
                                                <div className="service-item-promo-textual">
                                                    <p>Urgence Serrurier Montpellier pas cher est disponible 24H/24, 7J/7</p>
                                                </div>
                                                <div className="service-item-promo-seo-description">
                                                    <p>
                                                        Que ce soit pour une clé perdue, une serrure cassée ou une porte bloquée, notre équipe de serruriers qualifiés intervient rapidement pour résoudre vos problèmes de serrurerie. Nous offrons un service de qualité à des tarifs compétitifs afin de vous aider dans les situations d'urgence, sans compromettre votre budget. Ne laissez pas les problèmes de serrurerie vous causer du stress.
                                                    </p>
                                                </div>
                                                <div className="app-cta-btns-container">
                                                    <a href="tel:0646217592" className="app-cta-btn cta-phone-btn">
                                                        <BsFillPhoneVibrateFill />
                                                        <span>06 46 21 75 92</span>
                                                    </a>
                                                    <a href="mailto:contact@intervention-plombier-serrurier24h.fr" className="app-cta-btn cta-email-btn">
                                                        <MdMarkEmailRead />
                                                        <span>Demander un devis</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="decoration-section-with-quotation-btn">
                <div className="container-fluid decoration-section-container">
                    <div className="decoration-section-body">
                        <div className="row decoration-section-row">
                            <div className="col-lg-12 decoration-textual-content-col">
                                <div className="decoration-textual-content">
                                    <h3 className="decoration-section-title">Urgence Plombier et Serrurier pas cher à Montpellier</h3>
                                    <p>Faites appel à notre service d'urgence plombier. Nous intervenons rapidement pour résoudre tous vos problèmes de plomberie, que ce soit une fuite d'eau, un dégât des eaux ou un problème de canalisation. Notre équipe de professionnels expérimentés est disponible 24/7 pour vous fournir une assistance immédiate et de qualité. Contactez-nous dès maintenant pour une intervention rapide et efficace.</p>
                                </div>
                            </div>
                            <div className="col-lg-6 offset-lg-3 decoration-section-actions-btn-col">
                                <div className="row cta-row">
                                    <div className="col-lg-6 cta-col">
                                        <Button href="tel:0646217592" variant="default" className="app-cta-btn cta-phone-btn">
                                            <BsFillPhoneVibrateFill className="app-header-phone-icon" />
                                            <span className="phone-number">06 46 21 75 92</span>
                                        </Button>
                                    </div>
                                    <div className="col-lg-6 cta-col">
                                        <Button href="mailto:contact@intervention-plombier-serrurier24h.fr" variant="default" className="app-cta-btn cta-email-btn">
                                            <MdMarkEmailRead className="app-header-phone-icon" />
                                            <span className="phone-number">Demandez un devis</span>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="plumber-section">
                <div className="container plumber-section-container">
                    <div className="row plumber-section-row">
                        <div className="col-lg-8 offset-lg-4 plumber-section-col">
                            <div className="plumber-section-body">
                                <h3 className="section-title app-service-card-title ">Plombier pas cher à Montpellier</h3>
                                <div className="plumber-section-content">
                                    <img src={PlombierImageWithTools} alt="Plombier Montpellier pas cher 7j/7 24H/24" className="plumber-section-image" />
                                    <img src={PlombierImageWithTools2} alt="Plombier Montpellier pas cher 7j/7 24H/24" className="plumber-section-image-bis" />
                                    <div className="plumber-section-textual-container">
                                        <p>Lorsque vous êtes confronté à une urgence de plomberie à Montpellier, il est essentiel de pouvoir compter sur un service fiable, rapide et abordable. C'est là que l'entreprise Urgence Plombier Montpellier entre en jeu. Nous sommes une entreprise spécialisée dans les interventions d'urgence en plomberie, et nous mettons tout en œuvre pour résoudre vos problèmes de manière efficace et professionnelle. Dans cet article, nous vous présenterons nos services de plomberie d'urgence à Montpellier, en mettant l'accent sur notre engagement à fournir des solutions de qualité à des tarifs compétitifs.</p>
                                        <p>
                                            <strong>Services de plomberie d'urgence à Montpellier : </strong><br />
                                            Chez Urgence Plombier Montpellier, nous comprenons que les problèmes de plomberie peuvent survenir à tout moment, sans prévenir. C'est pourquoi nous avons mis en place un service d'urgence disponible 24 heures sur 24 et 7 jours sur 7 à Montpellier et ses environs. Que vous ayez une fuite d'eau, une canalisation bouchée, un problème de chauffe-eau ou tout autre souci de plomberie, notre équipe de plombiers expérimentés est prête à intervenir rapidement pour résoudre votre problème.
                                        </p>
                                        <p>
                                            Nos plombiers qualifiés et expérimentés sont équipés des outils nécessaires pour diagnostiquer et réparer efficacement les problèmes de plomberie les plus courants. Ils sont formés aux dernières techniques et technologies de plomberie, ce qui leur permet d'effectuer des réparations précises et durables. Nous nous engageons à fournir un service de qualité, quelle que soit l'urgence, tout en maintenant des tarifs abordables pour nos clients.
                                        </p>
                                        <p>
                                            <strong>Tarifs compétitifs : </strong><br />
                                            Chez Urgence Plombier Montpellier, nous sommes conscients de l'importance de proposer des tarifs compétitifs à nos clients. Nous comprenons que les problèmes de plomberie peuvent être stressants sur le plan financier, c'est pourquoi nous nous efforçons de maintenir nos prix abordables sans compromettre la qualité de notre travail. Lorsque vous faites appel à nos services, vous pouvez avoir l'assurance que vous recevrez un travail professionnel à un prix juste et transparent.
                                        </p>
                                    </div>
                                </div>
                                <div className="row cta-row">
                                    <div className="col-lg-6 cta-col">
                                        <Button href="tel:0646217592" variant="default" className="app-cta-btn cta-phone-btn">
                                            <BsFillPhoneVibrateFill className="app-header-phone-icon" />
                                            <span className="phone-number">06 46 21 75 92</span>
                                        </Button>
                                    </div>
                                    <div className="col-lg-6 cta-col">
                                        <Button href="mailto:contact@intervention-plombier-serrurier24h.fr" variant="default" className="app-cta-btn cta-email-btn">
                                            <MdMarkEmailRead className="app-header-phone-icon" />
                                            <span className="phone-number">Demandez un devis</span>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="locksmith-section">
                <div className="container locksmith-section-container">
                    <div className="row locksmith-section-row">
                        <div className="col-lg-8 locksmith-section-col">
                            <div className="locksmith-section-body">
                                <h3 className="section-title app-service-card-title ">Serrurier pas cher à Montpellier</h3>
                                <div className="locksmith-section-content">
                                    <img src={SerrurierImageAbsolute} alt="Plombier Montpellier pas cher 7j/7 24H/24" className="locksmith-section-image" />
                                    <img src={SerrurierImageWithTools2} alt="Plombier Montpellier pas cher 7j/7 24H/24" className="locksmith-section-image-bis" />
                                    <div className="locksmith-section-textual-container">
                                        <p>Lorsque vous êtes confronté à une urgence de plomberie à Montpellier, il est essentiel de pouvoir compter sur un service fiable, rapide et abordable. C'est là que l'entreprise Urgence Plombier Montpellier entre en jeu. Nous sommes une entreprise spécialisée dans les interventions d'urgence en plomberie, et nous mettons tout en œuvre pour résoudre vos problèmes de manière efficace et professionnelle. Dans cet article, nous vous présenterons nos services de plomberie d'urgence à Montpellier, en mettant l'accent sur notre engagement à fournir des solutions de qualité à des tarifs compétitifs.</p>
                                        <p>
                                            <strong>Services de plomberie d'urgence à Montpellier : </strong><br />
                                            Chez Urgence Plombier Montpellier, nous comprenons que les problèmes de plomberie peuvent survenir à tout moment, sans prévenir. C'est pourquoi nous avons mis en place un service d'urgence disponible 24 heures sur 24 et 7 jours sur 7 à Montpellier et ses environs. Que vous ayez une fuite d'eau, une canalisation bouchée, un problème de chauffe-eau ou tout autre souci de plomberie, notre équipe de plombiers expérimentés est prête à intervenir rapidement pour résoudre votre problème.
                                        </p>
                                        <p>
                                            Nos plombiers qualifiés et expérimentés sont équipés des outils nécessaires pour diagnostiquer et réparer efficacement les problèmes de plomberie les plus courants. Ils sont formés aux dernières techniques et technologies de plomberie, ce qui leur permet d'effectuer des réparations précises et durables. Nous nous engageons à fournir un service de qualité, quelle que soit l'urgence, tout en maintenant des tarifs abordables pour nos clients.
                                        </p>
                                        <p>
                                            <strong>Tarifs compétitifs : </strong><br />
                                            Chez Urgence Plombier Montpellier, nous sommes conscients de l'importance de proposer des tarifs compétitifs à nos clients. Nous comprenons que les problèmes de plomberie peuvent être stressants sur le plan financier, c'est pourquoi nous nous efforçons de maintenir nos prix abordables sans compromettre la qualité de notre travail. Lorsque vous faites appel à nos services, vous pouvez avoir l'assurance que vous recevrez un travail professionnel à un prix juste et transparent.
                                        </p>
                                    </div>
                                </div>
                                <div className="row cta-row">
                                    <div className="col-lg-6 cta-col">
                                        <Button href="tel:0646217592" variant="default" className="app-cta-btn cta-phone-btn">
                                            <BsFillPhoneVibrateFill className="app-header-phone-icon" />
                                            <span className="phone-number">06 46 21 75 92</span>
                                        </Button>
                                    </div>
                                    <div className="col-lg-6 cta-col">
                                        <Button href="mailto:contact@intervention-plombier-serrurier24h.fr" variant="default" className="app-cta-btn cta-email-btn">
                                            <MdMarkEmailRead className="app-header-phone-icon" />
                                            <span className="phone-number">Demandez un devis</span>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="pricing-section">
                <div className="container pricing-section-container">
                    <div className="pricing-section-body">
                        <h4 className="pricing-section-title">Tarifs compétitifs pour les services d'urgence plomberie et serrurerie à Montpellier</h4>
                        <div className="row pricing-row">
                            <div className="col-lg-4 pricing-table-col">
                                <div className="pricing-table-container">
                                    <h5 className="pricing-item-title">Nos prix plombiers</h5>
                                    <div className="pricing-items-container">
                                        <div className="pricing-item-card">
                                            <h6 className="pricing-item-card--title">Dégorgement WC</h6>
                                            <div className="pricing-item-card-body">
                                                <div className="pricing-item">
                                                    <span>80 &euro;</span>
                                                    <span className="pasuneurode-plus">pas 1&euro; de plus</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pricing-item-card">
                                            <h6 className="pricing-item-card--title">Dégorgement Furet</h6>
                                            <div className="pricing-item-card-body">
                                                <div className="pricing-item">
                                                    <span>80 &euro;</span>
                                                    <span className="pasuneurode-plus">pas 1&euro; de plus</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pricing-item-card">
                                            <h6 className="pricing-item-card--title">Main d'oeuvre</h6>
                                            <div className="pricing-item-card-body">
                                                <div className="pricing-item">
                                                    <span>40 &euro;</span>
                                                    <span className="pasuneurode-plus">pas 1&euro; de plus</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pricing-item-card">
                                            <h6 className="pricing-item-card--title">Déplacement Montpellier et environs</h6>
                                            <div className="pricing-item-card-body">
                                                <div className="pricing-item">
                                                    <span>40 &euro;</span>
                                                    <span className="pasuneurode-plus">pas 1&euro; de plus</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row cta-row">
                                        <div className="col-lg-6 cta-col">
                                            <Button href="tel:0646217592" variant="default" className="app-cta-btn cta-phone-btn">
                                                <BsFillPhoneVibrateFill className="app-header-phone-icon" />
                                                <span className="phone-number">06 46 21 75 92</span>
                                            </Button>
                                        </div>
                                        <div className="col-lg-6 cta-col">
                                            <Button href="mailto:contact@intervention-plombier-serrurier24h.fr" variant="default" className="app-cta-btn cta-email-btn">
                                                <MdMarkEmailRead className="app-header-phone-icon" />
                                                <span className="phone-number">Demandez un devis</span>
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 image-pricing-table-col">
                                <div className="pricing-table-container">
                                    <img src={PlombierPricingImage} alt="Plombier Montpellier pas cher" className="pricing-geo-image" />
                                    <img src={PricingGeoImage} alt="Plombier et Serrurier Montpellier pas cher" className="pricing-geo-image" />
                                    <img src={SerrurierPricingImage} alt="Serrurier Montpellier pas cher" className="pricing-geo-image" />
                                </div>
                            </div>
                            <div className="col-lg-4 pricing-table-col">
                                <div className="pricing-table-container">
                                    <h5 className="pricing-item-title">Nos prix serruriers</h5>
                                    <div className="pricing-items-container">
                                        <div className="pricing-item-card">
                                            <h6 className="pricing-item-card--title">Ouverture de portes fermées</h6>
                                            <div className="pricing-item-card-body">
                                                <div className="pricing-item">
                                                    <span>50 &euro;</span>
                                                    <span className="pasuneurode-plus">pas 1&euro; de plus</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pricing-item-card">
                                            <h6 className="pricing-item-card--title">Ouverture de portes blindées</h6>
                                            <div className="pricing-item-card-body">
                                                <div className="pricing-item">
                                                    <span>100 &euro;</span>
                                                    <span className="pasuneurode-plus">pas 1&euro; de plus</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pricing-item-card">
                                            <h6 className="pricing-item-card--title">Changement de serrure</h6>
                                            <div className="pricing-item-card-body">
                                                <div className="pricing-item">
                                                    <span>100 &euro;</span>
                                                    <span className="pasuneurode-plus">pas 1&euro; de plus</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pricing-item-card">
                                            <h6 className="pricing-item-card--title">Déplacement Montpellier et environs</h6>
                                            <div className="pricing-item-card-body">
                                                <div className="pricing-item">
                                                    <span>40 &euro;</span>
                                                    <span className="pasuneurode-plus">pas 1&euro; de plus</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row cta-row">
                                        <div className="col-lg-6 cta-col">
                                            <Button href="tel:0646217592" variant="default" className="app-cta-btn cta-phone-btn">
                                                <BsFillPhoneVibrateFill className="app-header-phone-icon" />
                                                <span className="phone-number">06 46 21 75 92</span>
                                            </Button>
                                        </div>
                                        <div className="col-lg-6 cta-col">
                                            <Button href="mailto:contact@intervention-plombier-serrurier24h.fr" variant="default" className="app-cta-btn cta-email-btn">
                                                <MdMarkEmailRead className="app-header-phone-icon" />
                                                <span className="phone-number">Demandez un devis</span>
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
        </div>
    )
}

export default Homepage