import React from 'react';
import { Link } from 'react-router-dom';
import SerrurierPageCover from '../../assets/images/services-serrurier/serrurier-page-apropos-image.jpeg';
import { BsFillPhoneVibrateFill } from 'react-icons/bs';
import { MdMarkEmailRead } from 'react-icons/md';
import { Button } from 'react-bootstrap';
import OuvertureDePortesFermees from '../../assets/images/services-serrurier/ouverture-de-portes-fermees.png';
import ChangementDeSerrure from '../../assets/images/services-serrurier/changement-de-serrure.jpeg';
import InstallationPortesBlindees from '../../assets/images/services-serrurier/installation-portes-blindees.jpeg';
import DepannageDUrgenceSerrurier from '../../assets/images/services-serrurier/depannage-durgence-serrurier.jpeg';
import PricingGeoImage from '../../assets/images/carte-agglomeration-montpellier.jpeg';

const Serrurier = () => {
    return (
        <div className="app-page-component">
            <div className="container-fluid app-page-cover-container">
                <div className="app-page-cover-content-body">
                    <div className="app-page-cover">
                        <h1 className="app-page-title">Urgence serrurier Montpellier pas cher</h1>
                        <div className="app-page-fil-ariane-container">
                            <Link to="/" className="app-page-fil-ariane-link">Accueil</Link>
                            &nbsp;&#10148;&nbsp;
                            <Link to="/serrurier-montpellier-pas-cher" className="app-page-fil-ariane-link active-page">Serrurier</Link>
                        </div>
                    </div>
                </div>
            </div>
            <section className="service-apropos-section">
                <div className="container service-apropos-section-container">
                    <div className="service-apropos-section-body">
                        <h2 className="service-apropos-section-title">Urgence Serrurier Montpellier Pas Cher : Des Experts Disponibles 24H/24, 7J/7</h2>
                        <div className="row service-apropos-row">
                            <div className="col-lg-5 service-apropos-col">
                                <div className="service-apropos-image-container">
                                    <img src={SerrurierPageCover} alt="Urgence plombier montpellier pas cher 24H/24 7J/7" className="page-service-image-cover" />
                                </div>
                            </div>
                            <div className="col-lg-7 service-apropos-col">
                                <div className="service-apropos-description-container">
                                    <div className="service-apropos-textual-body">
                                        <p>
                                            Lorsque vous vous retrouvez dans une situation d'urgence nécessitant les services d'un serrurier à Montpellier, il est crucial de faire appel à un professionnel réputé, rapide et à un prix abordable. Ne cherchez plus ! Notre équipe de serruriers d'urgence à Montpellier est là pour répondre à vos besoins, peu importe l'heure du jour ou de la nuit. Que vous ayez une porte claquée, une clé perdue ou une serrure endommagée, nous sommes là pour intervenir rapidement et efficacement.
                                        </p>
                                        <p>
                                            Nous comprenons l'urgence de la situation et nous nous engageons à être sur place dans les plus brefs délais dès que vous nous contactez. Notre équipe de serruriers expérimentés est formée pour gérer une variété de situations d'urgence, et nous sommes équipés des outils nécessaires pour résoudre vos problèmes de serrurerie rapidement et efficacement.
                                        </p>
                                        <p>
                                            En plus de notre réactivité, nous sommes fiers de proposer des tarifs compétitifs pour nos services de serrurerie d'urgence à Montpellier. Nous croyons fermement que la sécurité et la tranquillité d'esprit ne devraient pas être inaccessibles financièrement. C'est pourquoi nous nous efforçons de vous fournir des services de qualité à des prix abordables, sans compromettre notre niveau d'expertise et de professionnalisme.
                                        </p>
                                        <p>
                                            Faites-nous confiance pour tous vos besoins en serrurerie d'urgence à Montpellier. Nous mettons l'accent sur la satisfaction du client et nous nous assurons que chaque intervention est effectuée avec soin et précision. Notre équipe est composée de serruriers hautement qualifiés qui sont en mesure de gérer une large gamme de situations d'urgence, en utilisant des techniques avancées pour résoudre vos problèmes de serrurerie.
                                        </p>
                                        <p>
                                            Si vous vous retrouvez dans une situation d'urgence nécessitant l'intervention d'un serrurier à Montpellier, ne cherchez pas plus loin. Contactez-nous dès maintenant et laissez notre équipe de serruriers d'urgence compétents prendre en charge vos problèmes de serrurerie. Nous sommes là pour vous aider rapidement, efficacement et à un prix abordable. Votre satisfaction est notre priorité absolue.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="all-services">
                <div className="container all-services-container">
                    <div className="row all-services-row">
                        <div className="col-lg-12 all-services-col plumber-services-col">
                            <div className="all-services-content-body">
                                <div className="all-services-section-title-container">
                                    <div className="all-services-section-title-label-body">
                                        <div className="what-we-offer">Ce que nous offrons en serrurerie</div>
                                        <h2 className="all-services-section-title">Services d'Urgence Serrurier pas cher à Montpellier : Solutions Rapides et Abordables</h2>
                                    </div>
                                    <div className="all-services-section-title-description-body">
                                        <p>
                                            Besoin d'un serrurier en urgence à Montpellier, mais à un prix abordable ? Notre équipe de serruriers expérimentés est disponible 24/7 pour répondre à vos urgences serrurier. Nous intervenons rapidement pour minimiser les dégâts et vous offrir un confort optimal. Contactez-nous dès maintenant pour un service d'urgence plombier à Montpellier, avec des tarifs compétitifs.
                                        </p>
                                    </div>
                                </div>
                                <div className="app-page-service-items-containers">
                                    <div className="row app-page-service-items-row">
                                        <div id="app-page-service-depannage-durgence-serrurier" className="col-lg-12 app-page-service-item-col">
                                            <div className="app-page-service-item-content-body-container">
                                                <div className="app-page-service-item-image-container">
                                                    <img src={DepannageDUrgenceSerrurier} alt="Urgence serrurier montpellier pas cher" className="app-page-service-item-image" />
                                                </div>
                                                <div className="app-page-service-item-description-container">
                                                    <h3 className="app-page-service-item-title">Dépannage d'urgence serrurier</h3>
                                                    <div className="app-page-service-item-textual">
                                                        <p>
                                                            Faites confiance à notre équipe de serruriers spécialisés en dépannage d'urgence pour résoudre rapidement vos problèmes de serrurerie. Que vous ayez une porte claquée, une clé perdue ou une serrure endommagée, notre équipe réactive est prête à intervenir à tout moment. Nous offrons un service fiable et compétent, en mettant l'accent sur votre sécurité et votre satisfaction. De plus, nos tarifs compétitifs vous garantissent une assistance d'urgence abordable. Contactez-nous dès maintenant pour un dépannage d'urgence serrurier de qualité, assurant votre tranquillité d'esprit en cas de situation critique.
                                                        </p>
                                                        <div className="decoration-section-actions-btn-col">
                                                            <div className="row cta-row">
                                                                <div className="col-lg-6 cta-col">
                                                                    <Button href="tel:0646217592" variant="default" className="app-cta-btn cta-phone-btn">
                                                                        <BsFillPhoneVibrateFill className="app-header-phone-icon" />
                                                                        <span className="phone-number">06 46 21 75 92</span>
                                                                    </Button>
                                                                </div>
                                                                <div className="col-lg-6 cta-col">
                                                                    <Button href="mailto:contact@intervention-plombier-serrurier24h.fr" variant="default" className="app-cta-btn cta-email-btn">
                                                                        <MdMarkEmailRead className="app-header-phone-icon" />
                                                                        <span className="phone-number">Demandez un devis</span>
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="app-page-service-ouverture-portes-fermees" className="col-lg-12 app-page-service-item-col">
                                            <div className="app-page-service-item-content-body-container">
                                                <div className="app-page-service-item-image-container">
                                                    <img src={OuvertureDePortesFermees} alt="Ouverture de portes fermées avec Urgence plombier montpellier pas cher" className="app-page-service-item-image" />
                                                </div>
                                                <div className="app-page-service-item-description-container">
                                                    <h3 className="app-page-service-item-title">Ouverture de portes fermées</h3>
                                                    <div className="app-page-service-item-textual">
                                                        <p>
                                                            L'ouverture de portes fermées est un service essentiel fourni par des professionnels qualifiés pour résoudre les situations d'urgence lorsque vous êtes confronté à une porte verrouillée ou coincée. Que vous ayez perdu vos clés, que la serrure soit défectueuse ou que vous ayez simplement claqué la porte sans emporter vos clés, faire appel à des experts en ouverture de portes fermées est la solution. Ces spécialistes utilisent des techniques et des outils appropriés pour ouvrir votre porte rapidement et efficacement, sans endommager la serrure ou la porte elle-même. Que ce soit pour votre domicile, votre bureau ou votre véhicule, les professionnels de l'ouverture de portes fermées sont formés pour intervenir sur différents types de serrures et de portes, offrant une solution sûre et professionnelle. Lorsque vous vous retrouvez dans une situation d'urgence avec une porte fermée, ne paniquez pas. Contactez des experts en ouverture de portes fermées pour une assistance immédiate et fiable, vous permettant de retrouver l'accès à votre propriété en toute sécurité.
                                                        </p>
                                                        <div className="decoration-section-actions-btn-col">
                                                            <div className="row cta-row">
                                                                <div className="col-lg-6 cta-col">
                                                                    <Button href="tel:0646217592" variant="default" className="app-cta-btn cta-phone-btn">
                                                                        <BsFillPhoneVibrateFill className="app-header-phone-icon" />
                                                                        <span className="phone-number">06 46 21 75 92</span>
                                                                    </Button>
                                                                </div>
                                                                <div className="col-lg-6 cta-col">
                                                                    <Button href="mailto:contact@intervention-plombier-serrurier24h.fr" variant="default" className="app-cta-btn cta-email-btn">
                                                                        <MdMarkEmailRead className="app-header-phone-icon" />
                                                                        <span className="phone-number">Demandez un devis</span>
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="app-page-service-changemebt-de-serrure" className="col-lg-12 app-page-service-item-col">
                                            <div className="app-page-service-item-content-body-container">
                                                <div className="app-page-service-item-image-container">
                                                    <img src={ChangementDeSerrure} alt="Changement de serrure avec Urgence plombier montpellier pas cher" className="app-page-service-item-image" />
                                                </div>
                                                <div className="app-page-service-item-description-container">
                                                    <h3 className="app-page-service-item-title">Changement de serrure</h3>
                                                    <div className="app-page-service-item-textual">
                                                        <p>
                                                            Le débouchage de canalisations est une intervention essentielle pour résoudre les problèmes de refoulement d'eau et d'obstruction dans vos systèmes de plomberie. Notre équipe de professionnels expérimentés offre un service de débouchage de canalisations rapide et efficace pour rétablir le bon fonctionnement de vos installations. Que ce soit à cause d'accumulation de débris, de dépôts de graisse ou de racines d'arbres envahissant les tuyaux, nous utilisons des techniques spécialisées et des équipements de pointe pour éliminer les obstructions et rétablir le flux d'eau normal. Nous comprenons l'urgence de la situation et nous nous engageons à intervenir rapidement pour minimiser les désagréments. Notre équipe veille également à prendre des mesures préventives pour éviter les problèmes récurrents de bouchons dans vos canalisations. Avec notre service de débouchage de canalisations, vous pouvez être assuré d'une solution efficace et durable. N'hésitez pas à nous contacter dès maintenant pour un débouchage professionnel de vos canalisations, avec des résultats garantis et des tarifs compétitifs.
                                                        </p>
                                                        <div className="decoration-section-actions-btn-col">
                                                            <div className="row cta-row">
                                                                <div className="col-lg-6 cta-col">
                                                                    <Button href="tel:0646217592" variant="default" className="app-cta-btn cta-phone-btn">
                                                                        <BsFillPhoneVibrateFill className="app-header-phone-icon" />
                                                                        <span className="phone-number">06 46 21 75 92</span>
                                                                    </Button>
                                                                </div>
                                                                <div className="col-lg-6 cta-col">
                                                                    <Button href="mailto:contact@intervention-plombier-serrurier24h.fr" variant="default" className="app-cta-btn cta-email-btn">
                                                                        <MdMarkEmailRead className="app-header-phone-icon" />
                                                                        <span className="phone-number">Demandez un devis</span>
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="app-page-service-installation-de-portes-blindees" className="col-lg-12 app-page-service-item-col">
                                            <div className="app-page-service-item-content-body-container">
                                                <div className="app-page-service-item-image-container">
                                                    <img src={InstallationPortesBlindees} alt="Installation de portes blindées avec Urgence plombier montpellier pas cher" className="app-page-service-item-image" />
                                                </div>
                                                <div className="app-page-service-item-description-container">
                                                    <h3 className="app-page-service-item-title">Installation de portes blindées</h3>
                                                    <div className="app-page-service-item-textual">
                                                        <p>
                                                            L'installation de portes blindées est un moyen efficace de renforcer la sécurité et la protection de votre domicile ou de votre entreprise. Les portes blindées sont spécialement conçues pour résister aux tentatives d'effraction, offrant une barrière solide et dissuasive contre les intrusions. L'installation de portes blindées comprend la sélection d'une porte adaptée à vos besoins spécifiques, l'ajustement précis de la porte dans l'encadrement existant et l'installation de systèmes de verrouillage avancés pour garantir une sécurité maximale. Une porte blindée bien installée offre une tranquillité d'esprit en créant une couche supplémentaire de protection contre les cambriolages et les intrusions non autorisées. De plus, ces portes renforcent également l'isolation thermique et acoustique de votre espace, améliorant ainsi l'efficacité énergétique et le confort intérieur. Lors de l'installation de portes blindées, il est essentiel de faire appel à des professionnels expérimentés pour un travail de qualité et une intégration harmonieuse avec votre structure existante. Optez pour une installation de portes blindées fiable et sécurisée pour protéger votre propriété et votre tranquillité d'esprit.
                                                        </p>
                                                        <div className="decoration-section-actions-btn-col">
                                                            <div className="row cta-row">
                                                                <div className="col-lg-6 cta-col">
                                                                    <Button href="tel:0646217592" variant="default" className="app-cta-btn cta-phone-btn">
                                                                        <BsFillPhoneVibrateFill className="app-header-phone-icon" />
                                                                        <span className="phone-number">06 46 21 75 92</span>
                                                                    </Button>
                                                                </div>
                                                                <div className="col-lg-6 cta-col">
                                                                    <Button href="mailto:contact@intervention-plombier-serrurier24h.fr" variant="default" className="app-cta-btn cta-email-btn">
                                                                        <MdMarkEmailRead className="app-header-phone-icon" />
                                                                        <span className="phone-number">Demandez un devis</span>
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="decoration-section-with-quotation-btn">
                <div className="container-fluid decoration-section-container">
                    <div className="decoration-section-body">
                        <div className="row decoration-section-row">
                            <div className="col-lg-12 decoration-textual-content-col">
                                <div className="decoration-textual-content">
                                    <h3 className="decoration-section-title">Urgence Serrurier pas cher à Montpellier</h3>
                                    <p>Faites appel à notre service d'urgence serrurier. Nous intervenons rapidement pour résoudre tous vos problèmes de serrurerie, que ce soit une porte fermées, un changement de cylindre, etc... Notre équipe de professionnels expérimentés est disponible 24/7 pour vous fournir une assistance immédiate et de qualité. Contactez-nous dès maintenant pour une intervention rapide et efficace.</p>
                                </div>
                            </div>
                            <div className="col-lg-6 offset-lg-3 decoration-section-actions-btn-col">
                                <div className="row cta-row">
                                    <div className="col-lg-6 cta-col">
                                        <Button href="tel:0646217592" variant="default" className="app-cta-btn cta-phone-btn">
                                            <BsFillPhoneVibrateFill className="app-header-phone-icon" />
                                            <span className="phone-number">06 46 21 75 92</span>
                                        </Button>
                                    </div>
                                    <div className="col-lg-6 cta-col">
                                        <Button href="mailto:contact@intervention-plombier-serrurier24h.fr" variant="default" className="app-cta-btn cta-email-btn">
                                            <MdMarkEmailRead className="app-header-phone-icon" />
                                            <span className="phone-number">Demandez un devis</span>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="pricing-section">
                <div className="container pricing-section-container">
                    <div className="pricing-section-body">
                        <h4 className="pricing-section-title">Tarifs compétitifs pour les services d'urgence plombier à Montpellier</h4>
                        <div className="row pricing-row">
                            <div className="col-lg-7 pricing-table-col">
                                <div className="pricing-table-container">
                                    <h5 className="pricing-item-title">Nos prix serruriers</h5>
                                    <div className="pricing-items-container">
                                        <div className="pricing-item-card">
                                            <h6 className="pricing-item-card--title">Ouverture de portes fermées</h6>
                                            <div className="pricing-item-card-body">
                                                <div className="pricing-item">
                                                    <span>50 &euro;</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pricing-item-card">
                                            <h6 className="pricing-item-card--title">Ouverture de portes blindées</h6>
                                            <div className="pricing-item-card-body">
                                                <div className="pricing-item">
                                                    <span>100 &euro;</span>
                                                    <span className="pasuneurode-plus">pas 1&euro; de plus</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pricing-item-card">
                                            <h6 className="pricing-item-card--title">Changement de serrure</h6>
                                            <div className="pricing-item-card-body">
                                                <div className="pricing-item">
                                                    <span>100 &euro;</span>
                                                    <span className="pasuneurode-plus">pas 1&euro; de plus</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pricing-item-card">
                                            <h6 className="pricing-item-card--title">Déplacement Montpellier et environs</h6>
                                            <div className="pricing-item-card-body">
                                                <div className="pricing-item">
                                                    <span>40 &euro;</span>
                                                    <span className="pasuneurode-plus">pas 1&euro; de plus</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row cta-row">
                                        <div className="col-lg-6 cta-col">
                                            <Button href="tel:0646217592" variant="default" className="app-cta-btn cta-phone-btn">
                                                <BsFillPhoneVibrateFill className="app-header-phone-icon" />
                                                <span className="phone-number">06 46 21 75 92</span>
                                            </Button>
                                        </div>
                                        <div className="col-lg-6 cta-col">
                                            <Button href="mailto:contact@intervention-plombier-serrurier24h.fr" variant="default" className="app-cta-btn cta-email-btn">
                                                <MdMarkEmailRead className="app-header-phone-icon" />
                                                <span className="phone-number">Demandez un devis</span>
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5 image-pricing-table-col">
                                <div className="pricing-table-container">
                                    <img src={PricingGeoImage} alt="Plombier et Serrurier Montpellier pas cher" className="pricing-geo-image" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Serrurier