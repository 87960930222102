
import { Link } from 'react-router-dom';
import PlombierImageCover from '../../assets/images/services-plombier/plombier-page-apropos-image.jpeg';
import PlomberieGenerale from '../../assets/images/services-plombier/plomberie-generale.jpeg';
import { BsFillPhoneVibrateFill } from 'react-icons/bs';
import { MdMarkEmailRead } from 'react-icons/md';
import { Button } from 'react-bootstrap';
import ReparationFuitesImage from '../../assets/images/services-plombier/reparation-de-fuites.webp';
import DebouchageDeCanalisations from '../../assets/images/services-plombier/debouchage-de-canalisations.webp';
import InstallationSanitaire from '../../assets/images/services-plombier/installation-sanitaire.jpeg';
import PricingGeoImage from '../../assets/images/carte-agglomeration-montpellier.jpeg';

const Plombier = () => {
    return (
        <div className="app-page-component">
            <div className="container-fluid app-page-cover-container">
                <div className="app-page-cover-content-body">
                    <div className="app-page-cover">
                        <h1 className="app-page-title">Urgence plombier Montpellier pas cher</h1>
                        <div className="app-page-fil-ariane-container">
                            <Link to="/" className="app-page-fil-ariane-link">Accueil</Link>
                            &nbsp;&#10148;&nbsp;
                            <Link to="/plombier-montpellier-pas-cher" className="app-page-fil-ariane-link active-page">Plombier</Link>
                        </div>
                    </div>
                </div>
            </div>
            <section className="service-apropos-section">
                <div className="container service-apropos-section-container">
                    <div className="service-apropos-section-body">
                        <h2 className="service-apropos-section-title">Urgence Plombier Montpellier Pas Cher : Des Experts Disponibles 24H/24, 7J/7</h2>
                        <div className="row service-apropos-row">
                            <div className="col-lg-5 service-apropos-col">
                                <div className="service-apropos-image-container">
                                    <img src={PlombierImageCover} alt="Urgence plombier montpellier pas cher 24H/24 7J/7" className="page-service-image-cover" />
                                </div>
                            </div>
                            <div className="col-lg-7 service-apropos-col">
                                <div className="service-apropos-description-container">
                                    <div className="service-apropos-textual-body">
                                        <p>
                                            Vous recherchez un plombier d'urgence à Montpellier, mais vous souhaitez également bénéficier de tarifs abordables ? Ne cherchez plus ! Nos services d'urgence plombier à Montpellier sont là pour répondre à vos besoins avec efficacité et à des prix compétitifs.
                                        </p>
                                        <p>
                                            Qu'il s'agisse d'une fuite d'eau, d'une canalisation bouchée ou de tout autre problème de plomberie nécessitant une intervention immédiate, notre équipe de plombiers expérimentés est disponible 24 heures sur 24 et 7 jours sur 7 pour résoudre vos urgences de plomberie à Montpellier.
                                        </p>
                                        <p>
                                            Nous comprenons l'importance d'une réaction rapide lorsqu'il s'agit de problèmes de plomberie afin de minimiser les dégâts potentiels et de vous offrir un environnement confortable. C'est pourquoi nous nous engageons à intervenir dans les plus brefs délais dès que vous nous contactez.
                                        </p>
                                        <p>
                                            En plus de notre rapidité d'intervention, nous sommes fiers de proposer des tarifs abordables pour nos services d'urgence plombier à Montpellier. Nous croyons fermement que la qualité du travail ne devrait pas compromettre votre budget. Ainsi, nous nous efforçons de vous offrir des solutions efficaces à des prix compétitifs.
                                        </p>
                                        <p>
                                            N'attendez pas que les problèmes de plomberie s'aggravent. Contactez-nous dès maintenant pour bénéficier de nos services d'urgence plombier à Montpellier. Notre équipe professionnelle et fiable se fera un plaisir de vous assister rapidement et à un prix abordable. Faites-nous confiance pour résoudre vos problèmes de plomberie en toute tranquillité.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="all-services">
                <div className="container all-services-container">
                    <div className="row all-services-row">
                        <div className="col-lg-12 all-services-col plumber-services-col">
                            <div className="all-services-content-body">
                                <div className="all-services-section-title-container">
                                    <div className="all-services-section-title-label-body">
                                        <div className="what-we-offer">Ce que nous offrons en plomberie</div>
                                        <h2 className="all-services-section-title">Services d'Urgence Plombier pas cher à Montpellier : Solutions Rapides et Abordables</h2>
                                    </div>
                                    <div className="all-services-section-title-description-body">
                                        <p>
                                            Besoin d'un plombier en urgence à Montpellier, mais à un prix abordable ? Notre équipe de plombiers expérimentés est disponible 24/7 pour répondre à vos urgences de plomberie. Nous intervenons rapidement pour minimiser les dégâts et vous offrir un confort optimal. Contactez-nous dès maintenant pour un service d'urgence plombier à Montpellier, avec des tarifs compétitifs.
                                        </p>
                                    </div>
                                </div>
                                <div className="app-page-service-items-containers">
                                    <div className="row app-page-service-items-row">
                                        <div id="app-page-service-plomberie-generale" className="col-lg-12 app-page-service-item-col">
                                            <div className="app-page-service-item-content-body-container">
                                                <div className="app-page-service-item-image-container">
                                                    <img src={PlomberieGenerale} alt="Urgence plombier montpellier pas cher" className="app-page-service-item-image" />
                                                </div>
                                                <div className="app-page-service-item-description-container">
                                                    <h3 className="app-page-service-item-title">Plomberie Générale</h3>
                                                    <div className="app-page-service-item-textual">
                                                        <p>
                                                            Pour une installation de plomberie générale fiable et de qualité, faites confiance à notre équipe d'experts. Que ce soit pour une nouvelle construction ou une rénovation, nous sommes là pour vous accompagner. Notre service d'installation sur la plomberie générale couvre tous les aspects essentiels, des tuyaux aux raccordements en passant par les robinets et les systèmes de drainage. Avec des matériaux de qualité et des techniques avancées, nous garantissons des résultats durables et efficaces. Notre équipe respecte les normes les plus strictes de l'industrie, assurant un fonctionnement optimal de votre système. Que vous ayez besoin d'une installation complète ou de modifications spécifiques, nous personnalisons nos services pour répondre à vos besoins. Faites confiance à notre expertise et à notre engagement envers votre satisfaction. Contactez-nous dès maintenant pour une installation de plomberie générale de qualité supérieure.
                                                        </p>
                                                        <div className="decoration-section-actions-btn-col">
                                                            <div className="row cta-row">
                                                                <div className="col-lg-6 cta-col">
                                                                    <Button href="tel:0646217592" variant="default" className="app-cta-btn cta-phone-btn">
                                                                        <BsFillPhoneVibrateFill className="app-header-phone-icon" />
                                                                        <span className="phone-number">06 46 21 75 92</span>
                                                                    </Button>
                                                                </div>
                                                                <div className="col-lg-6 cta-col">
                                                                    <Button href="mailto:contact@intervention-plombier-serrurier24h.fr" variant="default" className="app-cta-btn cta-email-btn">
                                                                        <MdMarkEmailRead className="app-header-phone-icon" />
                                                                        <span className="phone-number">Demandez un devis</span>
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="app-page-service-reparation-defuites" className="col-lg-12 app-page-service-item-col">
                                            <div className="app-page-service-item-content-body-container">
                                                <div className="app-page-service-item-image-container">
                                                    <img src={ReparationFuitesImage} alt="Réparation de fuites avec Urgence plombier montpellier pas cher" className="app-page-service-item-image" />
                                                </div>
                                                <div className="app-page-service-item-description-container">
                                                    <h3 className="app-page-service-item-title">Réparation de fuites</h3>
                                                    <div className="app-page-service-item-textual">
                                                        <p>
                                                            Lorsqu'une fuite survient dans votre domicile, il est essentiel d'agir rapidement pour éviter tout dommage supplémentaire. Notre équipe de professionnels qualifiés est spécialisée dans la réparation de fuites, offrant des solutions rapides et efficaces pour restaurer l'intégrité de vos installations. Que ce soit une fuite d'eau visible ou une fuite dissimulée derrière les murs, nous utilisons des techniques avancées de détection pour localiser précisément la source du problème. Une fois la fuite identifiée, nous mettons en œuvre des méthodes de réparation durables et fiables pour éliminer la fuite et prévenir les récidives. Avec notre service de réparation de fuite, vous pouvez être tranquille, sachant que vous avez confié votre problème à des experts compétents. Ne laissez pas une fuite gâcher votre confort et endommager votre propriété. Contactez-nous dès maintenant pour une réparation de fuite professionnelle et efficace. Votre satisfaction est notre priorité, et nous nous engageons à vous offrir des résultats durables à des tarifs compétitifs.
                                                        </p>
                                                        <div className="decoration-section-actions-btn-col">
                                                            <div className="row cta-row">
                                                                <div className="col-lg-6 cta-col">
                                                                    <Button href="tel:0646217592" variant="default" className="app-cta-btn cta-phone-btn">
                                                                        <BsFillPhoneVibrateFill className="app-header-phone-icon" />
                                                                        <span className="phone-number">06 46 21 75 92</span>
                                                                    </Button>
                                                                </div>
                                                                <div className="col-lg-6 cta-col">
                                                                    <Button href="mailto:contact@intervention-plombier-serrurier24h.fr" variant="default" className="app-cta-btn cta-email-btn">
                                                                        <MdMarkEmailRead className="app-header-phone-icon" />
                                                                        <span className="phone-number">Demandez un devis</span>
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="app-page-service-debouchage-de-canalisation" className="col-lg-12 app-page-service-item-col">
                                            <div className="app-page-service-item-content-body-container">
                                                <div className="app-page-service-item-image-container">
                                                    <img src={DebouchageDeCanalisations} alt="Débouchage de canalisation avec Urgence plombier montpellier pas cher" className="app-page-service-item-image" />
                                                </div>
                                                <div className="app-page-service-item-description-container">
                                                    <h3 className="app-page-service-item-title">Débouchage de canalisation</h3>
                                                    <div className="app-page-service-item-textual">
                                                        <p>
                                                            Le débouchage de canalisations est une intervention essentielle pour résoudre les problèmes de refoulement d'eau et d'obstruction dans vos systèmes de plomberie. Notre équipe de professionnels expérimentés offre un service de débouchage de canalisations rapide et efficace pour rétablir le bon fonctionnement de vos installations. Que ce soit à cause d'accumulation de débris, de dépôts de graisse ou de racines d'arbres envahissant les tuyaux, nous utilisons des techniques spécialisées et des équipements de pointe pour éliminer les obstructions et rétablir le flux d'eau normal. Nous comprenons l'urgence de la situation et nous nous engageons à intervenir rapidement pour minimiser les désagréments. Notre équipe veille également à prendre des mesures préventives pour éviter les problèmes récurrents de bouchons dans vos canalisations. Avec notre service de débouchage de canalisations, vous pouvez être assuré d'une solution efficace et durable. N'hésitez pas à nous contacter dès maintenant pour un débouchage professionnel de vos canalisations, avec des résultats garantis et des tarifs compétitifs.
                                                        </p>
                                                        <div className="decoration-section-actions-btn-col">
                                                            <div className="row cta-row">
                                                                <div className="col-lg-6 cta-col">
                                                                    <Button href="tel:0646217592" variant="default" className="app-cta-btn cta-phone-btn">
                                                                        <BsFillPhoneVibrateFill className="app-header-phone-icon" />
                                                                        <span className="phone-number">06 46 21 75 92</span>
                                                                    </Button>
                                                                </div>
                                                                <div className="col-lg-6 cta-col">
                                                                    <Button href="mailto:contact@intervention-plombier-serrurier24h.fr" variant="default" className="app-cta-btn cta-email-btn">
                                                                        <MdMarkEmailRead className="app-header-phone-icon" />
                                                                        <span className="phone-number">Demandez un devis</span>
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="app-page-service-installation-de-sanitaires" className="col-lg-12 app-page-service-item-col">
                                            <div className="app-page-service-item-content-body-container">
                                                <div className="app-page-service-item-image-container">
                                                    <img src={InstallationSanitaire} alt="Installation de sanitaires avec Urgence plombier montpellier pas cher" className="app-page-service-item-image" />
                                                </div>
                                                <div className="app-page-service-item-description-container">
                                                    <h3 className="app-page-service-item-title">Installation de sanitaires</h3>
                                                    <div className="app-page-service-item-textual">
                                                        <p>
                                                            L'installation de sanitaires est une étape cruciale dans la création ou la rénovation de votre espace de vie. Notre équipe d'experts qualifiés vous propose un service professionnel d'installation de sanitaires pour répondre à tous vos besoins. Que vous souhaitiez installer de nouveaux lavabos, des toilettes, des douches ou des baignoires, nous sommes là pour vous accompagner dans chaque étape du processus. Nous vous conseillons sur les meilleures options en fonction de vos préférences esthétiques, de votre budget et de la configuration de votre espace. Grâce à notre expertise, vous pouvez être certain que les installations sanitaires seront réalisées selon les normes les plus élevées, garantissant ainsi leur bon fonctionnement et leur durabilité. En optant pour notre service d'installation de sanitaires, vous bénéficiez d'une solution clé en main, réalisée avec précision et dans les délais impartis. Contactez-nous dès maintenant pour profiter de notre savoir-faire en matière d'installation de sanitaires, et transformez votre espace en un environnement confortable, fonctionnel et esthétiquement plaisant.
                                                        </p>
                                                        <div className="decoration-section-actions-btn-col">
                                                            <div className="row cta-row">
                                                                <div className="col-lg-6 cta-col">
                                                                    <Button href="tel:0646217592" variant="default" className="app-cta-btn cta-phone-btn">
                                                                        <BsFillPhoneVibrateFill className="app-header-phone-icon" />
                                                                        <span className="phone-number">06 46 21 75 92</span>
                                                                    </Button>
                                                                </div>
                                                                <div className="col-lg-6 cta-col">
                                                                    <Button href="mailto:contact@intervention-plombier-serrurier24h.fr" variant="default" className="app-cta-btn cta-email-btn">
                                                                        <MdMarkEmailRead className="app-header-phone-icon" />
                                                                        <span className="phone-number">Demandez un devis</span>
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="decoration-section-with-quotation-btn">
                <div className="container-fluid decoration-section-container">
                    <div className="decoration-section-body">
                        <div className="row decoration-section-row">
                            <div className="col-lg-12 decoration-textual-content-col">
                                <div className="decoration-textual-content">
                                    <h3 className="decoration-section-title">Urgence Plombier pas cher à Montpellier</h3>
                                    <p>Faites appel à notre service d'urgence plombier. Nous intervenons rapidement pour résoudre tous vos problèmes de plomberie, que ce soit une fuite d'eau, un dégât des eaux ou un problème de canalisation. Notre équipe de professionnels expérimentés est disponible 24/7 pour vous fournir une assistance immédiate et de qualité. Contactez-nous dès maintenant pour une intervention rapide et efficace.</p>
                                </div>
                            </div>
                            <div className="col-lg-6 offset-lg-3 decoration-section-actions-btn-col">
                                <div className="row cta-row">
                                    <div className="col-lg-6 cta-col">
                                        <Button href="tel:0646217592" variant="default" className="app-cta-btn cta-phone-btn">
                                            <BsFillPhoneVibrateFill className="app-header-phone-icon" />
                                            <span className="phone-number">06 46 21 75 92</span>
                                        </Button>
                                    </div>
                                    <div className="col-lg-6 cta-col">
                                        <Button href="mailto:contact@intervention-plombier-serrurier24h.fr" variant="default" className="app-cta-btn cta-email-btn">
                                            <MdMarkEmailRead className="app-header-phone-icon" />
                                            <span className="phone-number">Demandez un devis</span>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="pricing-section">
                <div className="container pricing-section-container">
                    <div className="pricing-section-body">
                        <h4 className="pricing-section-title">Tarifs compétitifs pour les services d'urgence plombier à Montpellier</h4>
                        <div className="row pricing-row">
                            <div className="col-lg-7 pricing-table-col">
                                <div className="pricing-table-container">
                                    <h5 className="pricing-item-title">Nos prix plombiers</h5>
                                    <div className="pricing-items-container">
                                        <div className="pricing-item-card">
                                            <h6 className="pricing-item-card--title">Dégorgement WC</h6>
                                            <div className="pricing-item-card-body">
                                                <div className="pricing-item">
                                                    <span>80 &euro;</span>
                                                    <span className="pasuneurode-plus">pas 1&euro; de plus</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pricing-item-card">
                                            <h6 className="pricing-item-card--title">Dégorgement Furet</h6>
                                            <div className="pricing-item-card-body">
                                                <div className="pricing-item">
                                                    <span>80 &euro;</span>
                                                    <span className="pasuneurode-plus">pas 1&euro; de plus</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pricing-item-card">
                                            <h6 className="pricing-item-card--title">Main d'oeuvre</h6>
                                            <div className="pricing-item-card-body">
                                                <div className="pricing-item">
                                                    <span>40 &euro;</span>
                                                    <span className="pasuneurode-plus">pas 1&euro; de plus</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pricing-item-card">
                                            <h6 className="pricing-item-card--title">Déplacement Montpellier et environs</h6>
                                            <div className="pricing-item-card-body">
                                                <div className="pricing-item">
                                                    <span>40 &euro;</span>
                                                    <span className="pasuneurode-plus">pas 1&euro; de plus</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row cta-row">
                                        <div className="col-lg-6 cta-col">
                                            <Button href="tel:0646217592" variant="default" className="app-cta-btn cta-phone-btn">
                                                <BsFillPhoneVibrateFill className="app-header-phone-icon" />
                                                <span className="phone-number">06 46 21 75 92</span>
                                            </Button>
                                        </div>
                                        <div className="col-lg-6 cta-col">
                                            <Button href="mailto:contact@intervention-plombier-serrurier24h.fr" variant="default" className="app-cta-btn cta-email-btn">
                                                <MdMarkEmailRead className="app-header-phone-icon" />
                                                <span className="phone-number">Demandez un devis</span>
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5 image-pricing-table-col">
                                <div className="pricing-table-container">
                                    <img src={PricingGeoImage} alt="Plombier et Serrurier Montpellier pas cher" className="pricing-geo-image" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Plombier